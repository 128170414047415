const Homeproduct=
[
    {
        id:1,
        Name:'Aum Pulser Dual Coil',
        image:'image/p1.png',
        cat:'aum pulser',
        type:'medical',
    },
    {
        id:2,
        Name:'PEMF MAT',
        
        image:'image/p2.png',
        cat:'mat',
        type:'medical',
    },
    {
        id:3,
        Name:'PMS',
        
        image:'image/p3.png',
        cat:'pms',
        type:'medical',
    },
    {
        id:4,
        Name:'KNEE BRACES',
        
        image:'image/p4.png',
        cat:'knee braces',
        type:'medical',
    },
    {
        id:5,
        Name:'BIOFIELD 6 coil',
        
        image:'image/p5.png',
        cat:'biofield',
        type:'medical',
    },
    {
        id:6,
        Name:'SOUND BOX',
       
        image:'image/p6.png',
        cat:'sound box',
        type:'Automotive',
    },
    {
        id:7,
        Name:'DAS',
        
        image:'image/p7.png',
        cat:'das',
        type:'Automotive',
    },
    
    {
        id:8,
        Name:'POE',
        
        image:'image/p9.png',
        cat:'poe',
        type:'Automotive',
    },
    {
        id:9,
        Name:'4 Capsense INDUCTION',
        
        image:'image/p10.png',
        cat:'induction',
        type:'Kitchen',
    },
    {
        id:10,
        Name:'Aum Pulser single Coil',
        
        image:'image/p1-1.png',
        cat:'aum pulser',
        type:'medical',
    },
   
    {
        id:13,
        Name:'BIOFIELD 12 coil',
        
        image:'image/p17.png',
        cat:'biofield',
        type:'medical',
    },
    {
        id:14,
        Name:'SOUND BOX',
        
        image:'image/p6-1.png',
        cat:'sound box',
        type:'Automotive',
    },
    {
        id:15,
        Name:'7 Capsense INDUCTION',
        
        image:'image/p10.png',
        cat:'induction',
        type:'Kitchen',
    },
    {
        id:25,
        Name:'CCD INDUCTION',
        
        image:'image/p10.png',
        cat:'induction',
        type:'Kitchen',
    },
    {
        id:26,
        Name:'TRACKER',
        
        image:'image/p8.png',
        cat:'tracker',
        type:'Automotive',
    },
    {
        id:27,
        Name:'ADAS',
        
        image:'image/adas.png',
        cat:'adas',
        type:'Automotive',
    },
    {
        id:28,
        Name:'Colloidal Silver',
        
        image:'image/p13.png',
        cat:'silver',
        type:'commercial',
    },
    {
        id:1,
        Name:'Alert Seat',
        image:'image/p12.png',
        cat:'alert',
        type:'medical',
    },
    {
        id:16,
        Name:'Aum Pulser controller',
        
        image:'image/p1-2.png',
        cat:'aum pulser',
        type:'medical',
    },
    {
        id:11,
        Name:'PEMF MAT controller',
        
        image:'image/p2-1.png',
        cat:'mat',
        type:'medical',
    },
    {
        id:12,
        Name:'PMS controller',
        
        image:'image/PMS-3.png',
        cat:'pms',
        type:'medical',
    },
    {
        id:21,
        Name:'Aum Pulser adpter',
        
        image:'image/p1ad.png',
        cat:'aum pulser',
        type:'medical',
    },
    {
        id:17,
        Name:'PEMF MAT adpter',
        
        image:'image/p14.png',
        cat:'mat',
        type:'medical',
    },
    {
        id:18,
        Name:'PMS adpter',
        
        image:'image/p14.png',
        cat:'pms',
        type:'medical',
    },
   
    {
        id:20,
        Name:'BIOFIELD adpter',
       
        image:'image/p5-1.png',
        cat:'biofield',
        type:'medical',
    },
    
    {
        id:22,
        Name:'Aum Pulser coil',
        
        image:'image/p1coil.png',
        cat:'aum pulser',
        type:'medical',
    },
    {
        id:23,
        Name:'PMS electrode pads',
        
        image:'image/p15.png',
        cat:'pms',
        type:'medical',
    },
    {
        id:24,
        Name:'BIOFIELD coil',
        
        image:'image/p16.jpeg',
        cat:'biofield',
        type:'medical',
    },
    

]
export default Homeproduct
